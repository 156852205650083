import React, { useState } from 'react';
import './BreakCalculator.css';

const BreakCalculator = () => {
  const [hoursWorked, setHoursWorked] = useState(0);
  const [breakTime, setBreakTime] = useState(0);

  const calculateBreakTime = () => {
    const totalBreakMinutes = (8 * hoursWorked) / 60;
    setBreakTime(totalBreakMinutes);
  };

  return (
    <div className="break-calculator">
      <h2>Work Break Calculator</h2>
      <label>
        Hours Worked:
        <input
          type="number"
          value={hoursWorked}
          onChange={(e) => setHoursWorked(e.target.value)}
        />
      </label>
      <button onClick={calculateBreakTime}>Calculate Break Time</button>
      <p>Break Time: {breakTime.toFixed(2)} minutes</p>
    </div>
  );
};

export default BreakCalculator;