import React from 'react';
import BreakCalculator from './components/BreakCalculator';

function App() {
  return (
    <div>
      <BreakCalculator />
    </div>
  );
}

export default App;
